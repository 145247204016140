import { AppConfig, AuthProvider } from '@mit/aws-react'
import appConfig from '../../app-config-global'

export class AuthProviderLocal {
  getToken = async (): Promise<any> => {
    const authorizationToken = await new AuthProvider(Object.assign(appConfig)).getToken()
    return authorizationToken
  }

  logout = async (): Promise<any> => {
    await new AuthProvider(Object.assign(appConfig)).signOut()
  }

  refreshToken = async (): Promise<void> => {
    localStorage.setItem('RefreshAuthentication', 'true')
    await this.logout()
  }
}

const authProviderLocal = new AuthProviderLocal()
export default authProviderLocal
