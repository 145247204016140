import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'
import { store } from 'Store'
import { Logout } from 'TemplateConstants/components'
import { Initialization } from '@mit/aws-react'
import { useAppInitializer } from './TemplateConstants/components/initializer'
import reportWebVitals from './reportWebVitals'
import appConfig from './app-config-global'
import 'Scss/main.scss'

const WrappedOKTAApp: FC = () => {
  const { initializeApp } = useAppInitializer()

  const AppLazyLoad = React.lazy(
    async () =>
      await import('./TemplateConstants/components/Initialization').then(module => ({
        default: module.Initialization
      }))
  )

  return (
    <React.StrictMode>
      <Router>
        <Switch>
          <Route exact path='/logout' component={Logout} />
          <React.StrictMode>
            <Initialization
              App={AppLazyLoad}
              appInitializer={initializeApp}
              appConfigData={appConfig}
              loaderProps={{
                contactEmail: 'eol@mit.edu',
                name: 'Elx',
                isLoading: true,
                type: 'Default'
              }}
            />
          </React.StrictMode>
          ,
        </Switch>
      </Router>
    </React.StrictMode>
  )
}

ReactDOM.render(
  <StoreProvider store={store}>
    <WrappedOKTAApp />
  </StoreProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
