import { ELOPage } from '@mit/hui'
import { getEnv } from 'Common'
import { MainNavBar } from 'Components/nav'
import React from 'react'
import { Route, BrowserRouter as Router } from 'react-router-dom'

import { MyApplications } from 'Components/application/MyApplications'
import { ExperienceDetail } from 'Components/experience/ExperienceDetail'
import { Experiences } from 'Components/experience/Experiences'
import { useStoreState } from 'Store'
import { LoggedInUser } from 'TemplateConstants/components'

const App: React.FC = () => {
  const { heroComponent, wrapperClass } = useStoreState(store => store.globalModel)

  return (
    <Router>
      <ELOPage
        gaId={'G-CF96RCJDQN'}
        theme='default'
        environment={getEnv()}
        name={'Experiential Learning'}
        navigation={<MainNavBar />}
        profile={<LoggedInUser />}
        content={
          <>
            {/* <Switch> */}
            <Route exact path={'/'} component={Experiences} />
            <Route exact path={'/experience/:id'} component={ExperienceDetail} />
            <Route exact path={'/applications'} component={MyApplications} />
            {/* </Switch> */}
          </>
        }
        hero={heroComponent.value}
        wrapperClass={wrapperClass}
        headerLogo={<img src='/ELO-brand-logo.jpg' alt='Experiential Learning' height='45px' width='280px' className='appName' />}
      />
    </Router>
  )
}

export default App
