const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "apiPathElo": "elo-v2",
      "apiPathDigitalId": "digital-id-v1"
    }
  },
  "global": {
    "apiHost": "https://global.api.mit.edu",
    "paths": {
      "apiPathElo": "elo/v2",
      "apiPathDigitalId": "digital-id/v1"
    }
  }
};
export default apiConfigs;