import { BaseController as BaseApiController } from 'TemplateConstants/controller'
import apiConfig from '../api-config'
// Override any methods from TemplateConstant BaseController here. Extend all other controllers off this controller
export class BaseController extends BaseApiController {
  readonly apiPath

  constructor () {
    super()
    this.apiPath = apiConfig[this.configKey].paths.apiPathElo ?? ''
  }
}
