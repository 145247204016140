import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Textbox,
  Dropdown,
  AutoComplete,
  CalendarInput,
  Label,
  TextArea,
  Checkbox,
  Toggle,
  LocationSearch
  // Button
} from '@mit/hui'

import { CheckboxInputGroup } from '@mit/hui'
// import { LocationSearch } from '../input/LocationSearch'
import { getDateString, getNestedValue } from 'Common'
import apiKeys from '../../keys'

interface Props {
  name: string
  label: string
  editor:
    | 'textbox'
    | 'textarea'
    | 'toggle'
    | 'autocomplete'
    | 'dropdown'
    | 'person-search'
    | 'calendar'
    | 'checkbox'
    | 'checkboxinputgroup'
    | 'location-search'
  methods: ReturnType<typeof useForm>
  required?: boolean
  properties?: { [key: string]: any }
  rules?: { [key: string]: any }
  value?: any
  readonly?: boolean
  padded?: boolean
}

export const FormField: React.FC<Props> = ({ name, label, required, editor, methods, properties, value, readonly, padded = true }) => {
  const { control, errors, watch } = methods
  const mapBoxKey = apiKeys.keys.mapBoxKey

  const formData = (watch && watch()) || {}
  if (!properties) properties = {}

  let formEditor = null

  const getValue = () => {
    let value = getNestedValue(name, formData, editor)

    if (value === null || (Array.isArray(value) && value.length === 0)) return 'None'
    if (editor === 'calendar') return getDateString(value)
    if (typeof value === 'string') return value
    if (typeof value === 'boolean') return value ? 'Yes' : 'No'
    if (editor === 'checkboxinputgroup') {
      let items = properties?.groupOptions || []
      items = items.filter((item: any) => item.selected)
      if (!!items.length) return 'None'
      return (
        <ul>
          {items.map((item: any) => (
            <li>{`${item.title} ${item.show_input ? `: ${item.value}` : ''}`}</li>
          ))}
        </ul>
      )
    }

    if (Array.isArray(value))
      return (
        <ul>
          {value.map((row: any) => (
            <li>{row.text}</li>
          ))}
        </ul>
      )
    if (typeof value === 'object') return value.text
  }

  if (editor === 'textbox') {
    formEditor = (
      <Controller
        control={control}
        name={name}
        as={<Textbox name={name} required={required} properties={properties} {...properties} errors={errors} />}
        rules={{ required: required }}
        error={errors}
      />
    )
  }

  if (editor === 'textarea') {
    formEditor = (
      <Controller
        control={control}
        name={name}
        as={
          <TextArea
            name={name}
            required={required}
            rows={properties?.rows || 8}
            errors={errors}
            properties={properties}
            {...properties}
            // value={value}
          />
        }
        rules={{ required: required }}
        error={errors}
        // defaultValue={''}
      />
    )
  }

  if (editor === 'dropdown') {
    formEditor = (
      <Controller
        control={control}
        name={name}
        as={
          <Dropdown
            name=''
            dropdownItems={properties.items || []}
            text={properties.placeholder || ''}
            required={required}
            errors={errors}
            {...properties}
          />
        }
        rules={{ required: required }}
        error={errors}
      />
    )
  }
  if (editor === 'autocomplete') {
    formEditor = (
      <Controller
        name={name}
        control={control}
        as={
          <AutoComplete
            name={name}
            items={properties.items || []}
            searchOptions={{
              name: '',
              placeholderText: properties.placeholder
            }}
            required={required}
            errors={errors}
            limit={20}
            {...properties}
            onChange={(v: any) => console.log('keywords', v)}
          />
        }
        rules={{ required: required }}
        error={errors}
      />
    )
  }
  if (editor === 'calendar') {
    formEditor = (
      <Controller
        name={name}
        control={control}
        as={<CalendarInput id={name} required={required} errors={errors} {...properties} onChange={(v: string) => console.log(v)} />}
        rules={{ required: required }}
        error={errors}
      />
    )
  }

  // if (editor === 'person-search') {
  // 	formEditor = (
  // 		<Controller
  // 			name={name}
  // 			control={control}
  // 			as={
  // 				<PeopleSearch
  // 					name={name}
  // 					required={required}
  // 					errors={errors}
  // 					{...properties}
  // 				/>
  // 			}
  // 			rules={{ required: required }}
  // 			error={errors}
  // 		/>
  // 	)
  // }

  if (editor === 'location-search') {
    formEditor = (
      <Controller
        name={name}
        control={control}
        as={
          <LocationSearch
            name={name}
            required={required}
            errors={errors}
            {...properties}
            onChange={(e: any) => {
              debugger
            }}
            accessToken={mapBoxKey}
          />
        }
        rules={{ required: required }}
        error={errors}
      />
    )
  }

  if (editor === 'checkbox') {
    formEditor = (
      <Controller
        name={name}
        control={control}
        as={<Checkbox name={name} label='' register={methods.register} onClick={(v: any) => console.log(v)} />}
      />
    )
  }
  if (editor === 'toggle') {
    formEditor = (
      <Controller
        name={name}
        control={control}
        as={
          <Toggle
            name={name}
            {...properties}
            onClick={(v: any) => {
              methods.setValue(name, v.SWITCH_STATE.choice === 'Positive' ? true : false)
            }}
            choice={formData[name] ? 'Positive' : 'Negative'}
          />
        }
      />
    )
  }

  if (editor === 'checkboxinputgroup') {
    formEditor = (
      <Controller
        control={control}
        name={name}
        as={
          <CheckboxInputGroup
            label={label || ''}
            group={properties.group}
            name={name}
            groupOptions={properties.groupOptions}
            value={
              properties.groupOptions?.find((x: any) => {
                return x.selected
              })?.value
            }
            {...properties}
          />
        }
        rules={{ required: required }}
        register={methods.register}
        setValue={methods.setValue}
        errors={errors}
      />
    )
  }

  return (
    <>
      <div className={`d-flex flex-column ${padded ? 'mb-4' : ''} form-field `}>
        <Label text={label} />
        {readonly ? <span className='text-readonly'>{getValue()}</span> : formEditor}

        <p>{errors[name]}</p>
      </div>
    </>
  )
}

// EXAMPLES

/* <FormField name="textbox" editor="textbox" methods={methods} label="Text Box Input" required />

<FormField
	name="dropdown"
	editor="dropdown"
	methods={methods}
	label="Drop down"
	properties={{ items: listItems, placeholder: 'Select an item' }}
	required
/>
<FormField
	name="autocomplete"
	editor="autocomplete"
	methods={methods}
	label="Autocomplete"
	properties={{ items: listItems, placeholder: 'Search an item' }}
	required
/>

<FormField
	name="autocomplete-tags"
	editor="autocomplete"
	methods={methods}
	label="Autocomplete Tags"
	properties={{ items: listItems, placeholder: 'Search an item', type: 'tags' }}
	required
/>

<FormField
	name="person-search"
	editor="person-search"
	methods={methods}
	label="Person Serach"
	properties={{ placeholder: 'Search for a person' }}
	required
/>

<FormField
	name="calendar"
	editor="calendar"
	methods={methods}
	label="Calendar"
	properties={{ placeholder: 'Pick a Date' }}
	required
/> */
