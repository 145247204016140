import { Button, TemplateModal, TemplateModalAlignment } from '@mit/hui'
import React from 'react'
import { useStoreState } from 'Store'
import { AuthProviderLocal } from 'TemplateConstants/authorization'

interface SessionExpirationModalProps {
  customBody?: string
}

export const SessionExpirationModal: React.FC<SessionExpirationModalProps> = ({ customBody }) => {
  const { sessionHasExpired } = useStoreState(state => state.LoaderModel)

  return (
    <TemplateModal
      name=''
      show={sessionHasExpired}
      noExternalClose
      bodyAlignment={TemplateModalAlignment.Center}
      header={<h2>Session has expired</h2>}
      body={<>{customBody ?? <p className='mt-3'>Your session has expired. The application will reload and any progress may be lost.</p>}</>}
      footer={
        <>
          <Button
            text='Ok'
            onClick={() => {
              const authProvider = new AuthProviderLocal()
              authProvider.refreshToken()
            }}
          />
        </>
      }
    />
  )
}
