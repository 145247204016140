import React, { useEffect } from 'react'
import { Loader } from '@mit/hui'
import { useStoreState } from 'Store'
// import { AppInitializer } from 'Components/app'
import { SessionExpirationModal } from './SessionExpirationModal'
import { LoaderProps } from '@mit/hui/build/components/Loader'
import App from 'Components/app/App'
import useImagePreloader from 'Hooks/PreloadImage'

interface Props {
  loaderProps: LoaderProps
}

export const Initialization: React.FC = () => {
  const { hasAccess, isLoading } = useStoreState(state => state.LoaderModel)
  useImagePreloader(['/ELO-brand-logo.jpg'])

  console.log('Initialization', hasAccess)

  // if (isLoading || !hasAccess) {
  //   return (
  //     <>
  //       {/* {<AppInitializer />} */}
  //       <Loader contactEmail='' name='Elx' hasAccess={hasAccess} isLoading={isLoading} type={hasAccess || isLoading ? 'default' : 'noAccess'} />
  //     </>
  //   )
  // }

  // if (!isLoading || hasAccess) {
  //   return (
  //     <>
  //     <SessionExpirationModal />
  //     <App />
  //   </>
  //   )
  // }

  if (!isLoading) {
    if (!hasAccess) {
      return (
        <>
          {/* {<AppInitializer />} */}
          <Loader contactEmail='' name='Elx' hasAccess={hasAccess} isLoading={isLoading} type={hasAccess || isLoading ? 'default' : 'noAccess'} />
        </>
      )
    }
  }

  return (
    <>
      <SessionExpirationModal />
      <App />
    </>
  )
  // return <></>
}
