import { appInitialized } from '@mit/aws-react'
// import { AppInitializer } from 'Components/app/AppInitializer'
import { useState } from 'react'
import { LookupsController, OpportunityController, ProfileController } from 'Api/controller'
import { getPreferredFilters } from 'Common/Filter'
import React, { useEffect } from 'react'
import { useStoreActions } from 'Store'

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const [initializeApi, setInitializeApi] = useState(false)
  const { setHasAccess, setIsLoading } = useStoreActions(action => action.LoaderModel)
  const { setOpportunityFilters, setNotificationOptIn, setLookupItems, setPreferredOpportunityQuery } = useStoreActions(
    actions => actions.filterModel
  )
  const { setIsStudent, setHasApplications } = useStoreActions(actions => actions.profileModel)
  const { setAuth } = useStoreActions(action => action.globalModel)

  appInitialized({
    isLoading: true,
    hasAccess: false,
    hasError: false
  })

  const initializeApp = async (): Promise<void> => {
    console.log('useAppInitializer')

    const controller = new OpportunityController()
    const profileController = new ProfileController()
    const lookupController = new LookupsController()
    setInitializeApi(true)

    let resp: any = await Promise.allSettled([
      controller.getOpportunityFilters(),
      controller.getFilterPreference(),
      lookupController.getFilterOptions(),
      profileController.getInitialization()
    ])

    //filters
    if (resp[0].status === 'fulfilled') {
      let d = resp[0].value.map((d: any) => {
        d.component_type = d.component_type || 'checkboxgroup'
        return d
      })
      setOpportunityFilters(d || {})
    }

    if (resp[1].status === 'fulfilled') {
      setPreferredOpportunityQuery(getPreferredFilters(resp[1].value.filters))
      setNotificationOptIn(resp[1].value.notification_opt_in)
    }

    if (resp[2].status === 'fulfilled') {
      setLookupItems(resp[2].value || {})
    }

    if (resp[3].status === 'fulfilled') {
      setIsStudent(resp[3].value.is_student || false)
      setHasApplications(resp[3].value.has_applications || false)
      setHasAccess(resp[3].value)
      setAuth(resp[3].value)
    }

    // setHasAccess(true)
    if (resp.every((r: any) => r.status === 'fulfilled')) {
      setIsLoading(false)
      appInitialized({
        isLoading: false,
        hasAccess: resp[3].value,
        hasError: false
      })
    } else {
      appInitialized({
        isLoading: false,
        hasAccess: false,
        hasError: true
      })
    }
  }

  return { initializeApp }
}
